<div class="mt-5 rounded">
  <div class="grid grid-cols-2 gap-6">
    <div
      class="px-5 py-3 rounded shadow-md bg-white col-span-1 border-r-2 flex items-center"
    >
      <div class="bg-icon mr-3 p-0 rounded">
        <img
          src="@/assets/icons/icon_invoice_month.svg"
          alt=""
          class="w-10 h-10 fill-color"
        />
      </div>
      <div>
        <p class="font-bold text-2xl">{{ total_buy }}</p>
        <p>Hóa đơn đầu vào</p>
      </div>
    </div>
    <div
      class="px-5 py-3 rounded shadow-md bg-white col-span-1 border-r-2 flex items-center"
    >
      <div class="bg-icon mr-3 p-0 rounded">
        <img
          src="@/assets/icons/icon_invoice_month.svg"
          alt=""
          class="w-10 h-10 fill-color"
        />
      </div>
      <div>
        <p class="font-bold text-2xl">{{ total_sell }}</p>
        <p>Hóa đơn đầu ra</p>
      </div>
    </div>
  </div>
</div>

<div class="block justify-between md:flex items-center mt-5 rounded">
  <div class="flex items-center">
    <el-tabs v-model="invoiceType" @tab-click="handleTabSelected">
      <el-tab-pane label="Hóa đơn đầu vào" name="buy" class="w-full" />
      <el-tab-pane label="Hóa đơn đầu ra" name="sell" class="w-full" />
    </el-tabs>
  </div>
  <div class="text-right mt-2 flex justify-end md:mt-0 cursor-pointer">
    <el-alert
      v-if="sync_request_threshold"
      :closable="false"
      title="Băng thông đã đạt giới hạn! Vui lòng thử lại sau khi toàn bộ hoá đơn đồng bộ hoàn thành"
      type="warning"
      effect="dark"
      show-icon>
    </el-alert>
    

    <!-- Counter tag -->
    <el-tag class="mx-2" type="info" v-if="gov_max">{{gov_count}}/{{gov_max}}</el-tag>

    <!-- Alert button -->
    <el-alert
      :closable="false"
      type="error"
      v-if="company.gov_error_message"
      effect="dark"
      show-icon
      @click="handleGuide"
    >
      <template #title>
        <!-- <div v-if="company.consecutive_failed == 2" > -->
        <!--   Hướng dẫn xử lý -->
        <!-- </div> -->
        <div @click="handleGuide">
          {{company.gov_error_message}} (Chọn nút này để xem hướng dẫn xử lý)
        </div>
      </template>
    </el-alert>

    <!-- Sync button -->
    <el-alert
      v-if="!is_syncable"
      :closable="false"
      title="Hết số lượng hóa đơn đăng ký"
      type="warning"
      effect="dark"
      show-icon>
    </el-alert>
    <el-tooltip
      v-else
      class="item ml-2"
      effect="dark"
      :content="company.gov_error_message ? 'Vui lòng cập nhật mật khẩu' : 'Vui lòng nhập mật khẩu hoadondientu.gdt.gov.vn tại màn hình quản lý công ty để sử dụng tính năng đồng bộ'"
      :disabled="company.gov_invoice_password"
      placement="bottom"
    >
      <el-badge
        :is-dot="company.gov_invoice_password"
        class="item"
        :type="company.gov_error_message ? 'danger' : (company.gov_invoice_token ? 'success': 'warning')"
      >
        <el-button
          class="h-full"
          size="mini"
          :type="company.gov_invoice_password ? 'primary' : 'warning'"
          :icon="company.gov_invoice_password ? 'el-icon-refresh' : 'el-icon-warning'"
          :disabled="!company.gov_invoice_password || !!company.gov_error_message || sync_request_threshold"
          @click="showSyncModal = true"
        >
          {{ company.gov_invoice_password ? 'Đồng bộ' : 'Vui lòng nhập mật khẩu hoadondientu.gdt.gov.vn tại màn hình quản lý công ty' }}
        </el-button>
      </el-badge>
    </el-tooltip>

  </div>
</div>

<el-scrollbar class="height-table" v-loading="isLoading">
  <el-table
    :data="invoice_histories"
    border
    stripe
    style="width: 100%"
    empty-text="Không có dữ liệu"
    :header-cell-style="{ background: 'white' }"
    ref="tableData"
    @row-click="clickRow($event)"
    :row-class-name="handleRowUpdating"
  >

    <el-table-column
      type="index"
      width="70"
      :index="indexMethodMain"
      label="STT"
      align="center"
    />

    <el-table-column
      prop="created_at"
      label="Ngày lấy hóa đơn"
      width="180"
      min-width="160"
      align="center"
    >
      <template #default="props">
        <div>
          {{ formatDate(props.row.created_at) +" "+ formatTime(props.row.created_at) }}
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="created_at"
      label="Lần cập nhật cuối cùng"
      width="180"
      align="center"
    >
      <template #default="props">
        <div>
          {{ formatDate(props.row.updated_at) +" "+ formatTime(props.row.updated_at) }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Khoảng thời gian" align="center" width="230">
      <template #default="props">
        <div v-if="props.row.start_date">
          <el-tag type="info" effect="dark">{{ formatDate(props.row.start_date) }}</el-tag> ~
          <el-tag type="info" effect="dark">{{ formatDate(props.row.end_date) }}</el-tag>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Tài khoản yêu cầu" align="left" min-width="200">
      <template #default="props">
        <div v-if="props.row.user" class="flex justify-between items-center">
          {{ props.row.user.userType != "ITaxAdmin" && props.row.user.userType != "ITaxGeneral" ?
          props.row.user.emailQuanLyTaiKhoan : "Hệ thống" }}
          <UserIcon :user="props.row.user" v-if='props.row.user.userType != "ITaxAdmin" && props.row.user.userType != "ITaxGeneral"'/>
        </div>
      </template>
    </el-table-column>


    <el-table-column
      width="150"
      prop="total_success"
      label="Thành công"
      align="center"
    />
    <el-table-column
      width="150"
      prop="total_failed"
      label="Thất bại"
      align="center"
    />
    <el-table-column
      width="150"
      prop="total_pending"
      label="Chờ xử lý"
      align="center"
    >
      <template #default="props">
        <div class="flex gap-2 justify-center">
          <el-button :type="(props.row.total_pending || props.row.request_status == 0) && 'warning'"
            v-if="props.row.request_status != 2 && props.row.request_status != 3"
            :icon="props.row.total_pending || props.row.request_status == 0 ? 'el-icon-loading' : ''"
            size="mini"
            class="w-20">
            {{props.row.total_pending}}
          </el-button>

          <el-tooltip
            v-if="props.row.request_status == 2"
            class="item ml-2"
            effect="dark"
            :content="props.row.message"
            placement="left"
          >
            <el-tag
              type="danger"
              size="medium"
              icon="el-icon-warning"
              class="text-center w-20"
              effect="dark"
            >
              Lỗi
            </el-tag>
          </el-tooltip>

          <el-tooltip
            v-if="props.row.request_status == 3"
            class="item ml-2"
            effect="dark"
            content="Đã đạt giới hạn đồng bộ hóa đơn. Vui lòng liên hệ clientcare@itaxvn.com để được hỗ trợ"
            placement="left"
          >
            <el-tag
              type="warning"
              size="medium"
              icon="el-icon-warning"
              class="text-center w-20"
              effect="dark"
            >
              Giới hạn
            </el-tag>
          </el-tooltip>

        </div>
      </template>
    </el-table-column>

    <el-table-column
      prop="total_invoice_new"
      label="Hóa đơn mới"
      align="center"
      width="150"
    >
      <template #default="props">
        <el-button :type="props.row.total_invoice_new && 'success'" size="mini"
          class="w-16"
          >{{props.row.total_invoice_new}}</el-button
        >
      </template>
    </el-table-column>

    <el-table-column
      prop="total_invoice_update"
      label="Hóa đơn cập nhật"
      align="center"
      width="150"
    >
      <template #default="props">
        <el-button
          :type="props.row.total_invoice_update && 'primary'"
          size="mini"
          class="w-16"
          >{{props.row.total_invoice_update}}</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</el-scrollbar>
<div>
  <paginate
    :currentPage="paginate.currentPage"
    :totalItem="paginate.totalItem"
    :totalPage="paginate.totalPage"
    :limit="paginate.limit"
    @update:limit="handleLimitChange"
    @modifiedCurrentPage="handleCurrentPageChange($event)"
  />
</div>

<el-dialog
  title="Đồng bộ hóa đơn từ cơ quan thuế"
  v-model="showSyncModal"
  v-loading="isRequesting"
>
  <el-form ref="form" :model="form">
    <el-form-item class="w-full" label="Ngày đồng bộ">
      <template #label>
        <p class="leading-5">
          Ngày đồng bộ<small class="items-start block text-red-500"
            >(*Tối đa 1 tháng)</small
          >
        </p>
      </template>
      <el-date-picker
        v-model="syncRequest.date"
        type="daterange"
        start-placeholder="Start date"
        end-placeholder="End date"
        :shortcuts="shortcuts"
        :disabled-date="disabledDate"
        @change="handleRequestSyncDate"
      />
    </el-form-item>

    <el-form-item class="w-full" label="Loại hóa đơn">
      <el-switch
        v-model="syncRequest.invoice_type"
        class="ml-2"
        inline-prompt
        style="--el-switch-on-color: #0d84ff; --el-switch-off-color: #0d84ff"
        active-text="Hóa đơn đầu ra"
        inactive-text="Hóa đơn đầu vào"
      />
    </el-form-item>
  </el-form>

  <template #footer>
    <span class="dialog-footer justify-between flex align-middle">
      <div class="flex justify-center items-center">
        <small class="text-gray-500 mr-2" v-if="company.gov_invoice_password_expired_at">Mật khẩu hết hạn vào ngày:
          <b v-if="company.gov_invoice_password_expired_at != '2100-01-01T00:00:00'">{{ formatDate(company.gov_invoice_password_expired_at) }}</b>
          <b v-else>Không xác định</b>
        </small>

        <el-tooltip
          v-if="company.gov_invoice_password_message"
          effect="dark"
          :content="company.gov_invoice_password_message"
          placement="bottom"
        >
          <el-button title="Cảnh báo" type="danger" size="mini">Cảnh báo</el-button>
        </el-tooltip>
        <el-button @click="renew_password" type="warning" icon="el-icon-warning-outline" size="mini" v-if="ableToRenewPassword(company.gov_invoice_password_expired_at) && !company.gov_invoice_password_message">
          Gia hạn
        </el-button>
      </div>

      <div>
        <el-button @click="showSyncModal = false" icon="el-icon-arrow-left" size="mini">
          Quay lại
        </el-button>
        <el-button type="primary" @click="handleSyncRequest" :disabled="isRequesting" icon="el-icon-refresh" size="mini">
          Đồng bộ
        </el-button>
      </div>
    </span>
  </template>
</el-dialog>

<el-dialog title="Thông tin đồng bộ" v-model="showDetailDialog" width="85%">
  <template #title>
    <div class="flex">
      <span class="mr-3">Thông tin đồng bộ</span>
      <el-button class="ml-5" type="primary" @click="handleRereadDetail(false)" size="mini"
          :disabled="!company.gov_invoice_password || !!company.gov_error_message || govInvoiceHistorySelected.total_failed <= 0 || sync_request_threshold || !is_syncable"
      >Đọc lại hóa đơn lỗi</el-button>
      <el-button class="ml-5" type="warning" @click="handleRereadDetail(true)" size="mini" icon="el-icon-warning"
          :disabled="!company.gov_invoice_password || !!company.gov_error_message || sync_request_threshold || !is_syncable"
      >Đọc lại toàn bộ hóa đơn</el-button>
    </div>
  </template>
  <el-scrollbar class="height-table-detail" v-loading="isDetailLoading">
    <el-table
      :data="gov_invoices_history_details"
      border
      style="width: 100%"
      :span-method="arraySpanMethod"
    >
      <el-table-column
        type="index"
        label="STT"
        width="80"
        :index="indexMethod"
        align="center"
      />

      <!-- MST -->
      <el-table-column label="Mã số thuế" width="130">
        <template #default="props">
          <div v-if="props.row.invoice">
            {{ props.row.invoice ? (invoiceType == 'sell' ?
            props.row.invoice.buyer_tax_code :
            props.row.invoice.seller_tax_code) : '' }}
          </div>
          <div v-else>
            {{ props.row.invoice_buyer ? (invoiceType == 'sell' ?
            props.row.invoice_buyer.buyer_tax_code :
            props.row.invoice_buyer.seller_tax_code) : '' }}
          </div>
        </template>
      </el-table-column>

      <!-- seller/buyer name -->
      <el-table-column>
        <template #header>
          {{ invoiceType == 'sell' ? 'Khách hàng' : 'Nhà cung cấp' }}
        </template>
        <template #default="props">
          {{ props.row.invoice ? (invoiceType == 'sell' ?
          props.row.invoice.buyer_name : props.row.invoice.seller_name) : '' }}
        </template>
      </el-table-column>

      <!-- Issued Date -->
      <el-table-column label="Ngày phát hành" width="125px" align="center">
        <template #default="props">
          {{ props.row.invoice ? formatDate(props.row.invoice.issued_date) : ''
          }}
        </template>
      </el-table-column>

      <el-table-column
        prop="number"
        label="Số hóa đơn"
        width="100px"
        align="center"
      >
        <template #default="props">
          {{ String(props.row.number).padStart(7, '0') }}
        </template>
      </el-table-column>

      <el-table-column
        prop="form"
        label="Mẫu số"
        width="100px"
        align="center"
      />
      <el-table-column
        prop="serial"
        label="Ký hiệu"
        width="100px"
        align="center"
      />
      <el-table-column prop="status" label="Trạng thái" width="110px">
        <template #default="props">
          <el-tooltip
            class="item"
            effect="dark"
            :content="props.row.id"
            placement="bottom"
          >
            <el-tag
              :type="props.row.status == 1 ? 'info': (props.row.status == 0 || props.row.status == 3) ? 'warning': 'danger'"
              size="medium"
              icon="el-icon-warning"
              class="w-full text-center"
              effect="dark"
            >
              {{ props.row.status == 1 ? "Thành công": props.row.status == 0 ?
              'Chờ xử lý': props.row.status == 2 ? 'Lỗi': 'Hết số lượng hóa đơn đăng ký' }}
            </el-tag>

          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column prop="invoice" label="HĐ bán" width="100px">
        <template #default="props">
            <el-button
              v-if="props.row.status != 2"
              :type="props.row.invoice_status == 1 ? 'success': (props.row.invoice_status == 3 ? 'primary': 'warning')"
              size="mini"
              @click="handleClickInvoice(props.row.invoice)"
              class="w-full text-center"
              :disabled="!props.row.invoice"
              >{{ props.row.invoice_status == 1 ? 'Mới':
              (props.row.invoice_status == 3 ? 'Cập nhật': 'Chờ xử lý') }}
            </el-button>
          <el-button
            v-else
            type="warning"
            size="mini"
            class="w-full text-center"
            @click="handleReread(props.row.id)"
            >Đọc lại</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="invoice_buyer" label="HĐ mua" width="100px">
        <template #default="props">
            <el-button
              v-if="props.row.status != 2"
              :type="props.row.invoice_status == 1 ? 'success': (props.row.invoice_status == 3 ? 'primary': 'warning')"
              size="mini"
              @click="handleClickInvoice(props.row.invoice_buyer)"
              class="w-full text-center"
              :disabled="!props.row.invoice_buyer"
              >{{ props.row.invoice_status == 1 ? 'Mới':
              (props.row.invoice_status == 3 ? 'Cập nhật': 'Chờ xử lý') }}
            </el-button>
          <el-button
            v-else
            type="warning"
            size="mini"
            class="w-full text-center"
            @click="handleReread(props.row.id)"
            >Đọc lại</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showDetailDialog = false">
          Quay lại
        </el-button>
      </span>
    </template>
  </el-scrollbar>
  <div>
  <paginate
      :currentPage="detailPaginate.currentPage"
      :totalItem="detailPaginate.totalItem"
      :totalPage="detailPaginate.totalPage"
      :limit="detailPaginate.limit"
      @update:limit="handleLimitChangeDetail"
      @modifiedCurrentPage="handleCurrentPageChangeDetail($event)"
    />
  </div>

</el-dialog>
